const tooltips = {
  overall:
    'The Overall Score is the college’s combined score on 14 different components. Six of these components assess student perceptions of different aspects of the speech climate on their campus. The other seven assess behavior by administrators, faculty, and students regarding free expression on campus. This score can range from 0 to 100 points.',
  opennessRank:
    'Measures the student’s perceived ability to have conversations about difficult topics on campus.',
  toleranceRank:
    'Measures tolerance for allowing controversial speakers, liberal and conservative, on campus.',
  toleranceLiberalsRank:
    'Measures tolerance for allowing controversial liberal speakers on campus.',
  toleranceConservativesRank:
    'Measures tolerance for allowing controversial conservative speakers on campus.',
  toleranceForLiberalsRank:
    'Measures tolerance for allowing controversial liberal speakers on campus.',
  toleranceForConservativesRank:
    'Measures tolerance for allowing controversial conservative speakers on campus.',
  toleranceDifferenceRank:
    'Measures the difference in tolerance for allowing controversial liberal and conservative speakers on campus.',
  adminSupportRank: 'Measures student perception of their college’s support for free speech.',
  comfortRank:
    'Measures how comfortable students are expressing their thoughts via writing, in class, and among their peers and professors at their college.',
  disruptiveConductRank:
    'Measures how acceptable students find blocking entry, shouting down, and physical violence in preventing on-campus speakers from speech activity at their college.',
  speechCode:
    'A rating of the written policies governing student speech at each institution. Three substantive ratings are possible: "red light," "yellow light," and "green light." A fourth rating, “Warning,” is also possible. It is assigned to a private college or university when its policies clearly and consistently state that it prioritizes other values over a commitment to freedom of speech.',
  rank: "Rank is the position off the school based on its Overall Score. An up arrow means that the school's ranking improved compared to the other schools surveyed last year. A down arrow means that the school's performance declined compared to the other schools surveyed last year. No arrow means the school was surveyed for the first time this year.",

  responseCount: 'Number of students surveyed at this school.',
  speechClimate:
    'A rating for each campus, based on a school’s overall score, ranging from "Exceptional" to "Abysmal."',
  viewpointRatio:
    'The ratio of liberal to conservative students enrolled at the given college or university.',
  warningLabel:
    'A "Warning" rating is assigned by FIRE to a private college or university when its policies clearly and consistently state that it prioritizes other values over a commitment to freedom of speech.',

  students:
    'The number of times the administration at a school defended or sanctioned a student’s speech rights during a campus controversy from 2020 to 2024, as recorded in FIRE’s forthcoming Students Under Fire database.',
  supportedStudents:
    'The number of times the administration at a school defended a student’s speech rights during a campus controversy from 2020 to 2024, as recorded in FIRE’s forthcoming Students Under Fire database.',
  sanctionedStudents:
    'The number of times the administration at a school sanctioned a student for their expression during a campus controversy from 2020 to 2024, as recorded in FIRE’s forthcoming Students Under Fire database.',

  studentGroups:
    'The number of times the administration at a school defended or sanctioned a student group’s speech rights during a campus controversy from 2020 to 2024, as recorded in FIRE’s forthcoming Students Under Fire database.',
  supportedStudentGroups:
    'The number of times the administration at a school defended a student group’s speech rights during a campus controversy from 2020 to 2024, as recorded in FIRE’s forthcoming Students Under Fire database.',
  sanctionedStudentGroups:
    'The number of times the administration at a school sanctioned a student group for their expression during a campus controversy from 2020 to 2024, as recorded in FIRE’s forthcoming Students Under Fire database.',

  scholars:
    'The number of times the administration at a school defended or sanctioned a scholar’s speech rights during a campus controversy from 2020 to 2024, as recorded in FIRE’s Scholars Under Fire database.',
  supportedScholars:
    'The number of times the administration at a school defended a scholar’s speech rights during a campus controversy from 2020 to 2024, as recorded in FIRE’s Scholars Under Fire database.',
  sanctionedScholars:
    'The number of times the administration at a school sanctioned a scholar for their expression during a campus controversy from 2019 to 2024, as recorded in FIRE’s Scholars Under Fire database.',

  speakers: 'The number of times the administration at a school defended',
  supportedSpeakers:
    "The number of times the administration at a school defended a speaker's speech rights during a campus disinvitation attempt from 2020 to 2024, as recorded in FIRE's Campus Disinvitation database.",
  disinvitations:
    'The number successful disinvitations that occurred from 2019 to 2024, as recorded in FIRE’s Campus Disinvitation database.',

  tuition:
    'The published out-of-state tuition as according to the National Center for Education Statistics.',

  deplatformings:
    'Successfully deplatforming a speaker, as recorded in FIRE’s Campus Deplatforming database.',
  attemptedDisruptions:
    'Schools where an attempted disruption occurred received a penalty of two points.',

  honorRollStatements:
    'Honor Roll statements are the sum of High Honors (worth 2 points) and Honors (worth 1 point) for a school’s response to speech controversies. High Honors are awarded for unequivocally defending free expression during a speech controversy, while Honors are awarded for a strong, but less full-throated, defense.',
  selfCensorship:
    'Students were provided with a definition of self-censorship and then asked how often they self-censored in three different settings on campus',
}

export default tooltips
