const schoolTotalCountData = {
  surveyTitle: 'Campus Climate 2024',
  surveyId: '63b57b40fb0cc80019fb26cc',
  table: [
    [
      {
        value: 'total responses',
        count: 57714,
      },
    ],
    [
      {
        value: 'University of Virginia',
        unitId: 234076,
        count: 275,
      },
      {
        value: 'Michigan Technological University',
        unitId: 171128,
        count: 104,
      },
      {
        value: 'Florida State University',
        unitId: 134097,
        count: 186,
      },
      {
        value: 'Eastern Kentucky University',
        unitId: 156620,
        count: 365,
      },
      {
        value: 'Georgia Institute of Technology',
        unitId: 139755,
        count: 207,
      },
      {
        value: 'Claremont McKenna College',
        unitId: 112260,
        count: 103,
      },
      {
        value: 'North Carolina State University',
        unitId: 199193,
        count: 1082,
      },
      {
        value: 'Oregon State University',
        unitId: 209542,
        count: 251,
      },
      {
        value: 'University of North Carolina, Charlotte',
        unitId: 199139,
        count: 356,
      },
      {
        value: 'Mississippi State University',
        unitId: 176080,
        count: 327,
      },
      {
        value: 'Auburn University',
        unitId: 100858,
        count: 353,
      },
      {
        value: 'College of William & Mary',
        unitId: 231624,
        count: 295,
      },
      {
        value: 'East Carolina University',
        unitId: 198464,
        count: 502,
      },
      {
        value: 'Arizona State University',
        unitId: 104151,
        count: 370,
      },
      {
        value: 'Northeastern Illinois University',
        unitId: 147776,
        count: 94,
      },
      {
        value: 'George Mason University',
        unitId: 232186,
        count: 132,
      },
      {
        value: 'University of South Florida',
        unitId: 137351,
        count: 348,
      },
      {
        value: 'Kansas State University',
        unitId: 155399,
        count: 286,
      },
      {
        value: 'University of Louisville',
        unitId: 157289,
        count: 293,
      },
      {
        value: 'University of Mississippi',
        unitId: 176017,
        count: 102,
      },
      {
        value: 'Clemson University',
        unitId: 217882,
        count: 288,
      },
      {
        value: 'University of North Carolina, Greensboro',
        unitId: 199148,
        count: 114,
      },
      {
        value: 'University of Tulsa',
        unitId: 207971,
        count: 101,
      },
      {
        value: 'Appalachian State University',
        unitId: 197869,
        count: 358,
      },
      {
        value: 'University of Arizona',
        unitId: 104179,
        count: 410,
      },
      {
        value: 'Colorado School of Mines',
        unitId: 126775,
        count: 92,
      },
      {
        value: 'Duke University',
        unitId: 198419,
        count: 108,
      },
      {
        value: 'Northern Arizona University',
        unitId: 105330,
        count: 126,
      },
      {
        value: 'University of Colorado, Boulder',
        unitId: 126614,
        count: 523,
      },
      {
        value: 'Purdue University',
        unitId: 243780,
        count: 281,
      },
      {
        value: 'New Mexico State University',
        unitId: 188030,
        count: 106,
      },
      {
        value: 'Virginia Commonwealth University',
        unitId: 234030,
        count: 115,
      },
      {
        value: 'Washington and Lee University',
        unitId: 234207,
        count: 106,
      },
      {
        value: 'University of South Carolina',
        unitId: 218663,
        count: 187,
      },
      {
        value: 'Florida International University',
        unitId: 133951,
        count: 261,
      },
      {
        value: 'DePauw University',
        unitId: 150400,
        count: 123,
      },
      {
        value: 'James Madison University',
        unitId: 232423,
        count: 296,
      },
      {
        value: 'Wayne State University',
        unitId: 172644,
        count: 104,
      },
      {
        value: 'University of Maryland',
        unitId: 163286,
        count: 286,
      },
      {
        value: 'University of Alabama, Birmingham',
        unitId: 100663,
        count: 135,
      },
      {
        value: 'Carnegie Mellon University',
        unitId: 211440,
        count: 390,
      },
      {
        value: 'University of Hawaii',
        unitId: 141574,
        count: 100,
      },
      {
        value: 'University of Chicago',
        unitId: 144050,
        count: 279,
      },
      {
        value: 'Kent State University',
        unitId: 203517,
        count: 99,
      },
      {
        value: 'Georgia State University',
        unitId: 139940,
        count: 188,
      },
      {
        value: 'Worcester Polytechnic Institute',
        unitId: 168421,
        count: 212,
      },
      {
        value: 'University of Texas, El Paso',
        unitId: 228796,
        count: 144,
      },
      {
        value: 'University of Memphis',
        unitId: 220862,
        count: 337,
      },
      {
        value: 'University of Alabama, Huntsville',
        unitId: 100706,
        count: 176,
      },
      {
        value: 'Wright State University',
        unitId: 206604,
        count: 371,
      },
      {
        value: 'University of Oklahoma',
        unitId: 207500,
        count: 108,
      },
      {
        value: 'Oklahoma State University',
        unitId: 207388,
        count: 509,
      },
      {
        value: 'Towson University',
        unitId: 164076,
        count: 101,
      },
      {
        value: 'University of Wisconsin, Milwaukee',
        unitId: 240453,
        count: 359,
      },
      {
        value: 'University of Missouri, St. Louis',
        unitId: 178420,
        count: 249,
      },
      {
        value: 'University of Missouri, Kansas City',
        unitId: 178402,
        count: 188,
      },
      {
        value: 'Miami University',
        unitId: 204024,
        count: 285,
      },
      {
        value: 'Arkansas State University',
        unitId: 106458,
        count: 405,
      },
      {
        value: 'University of New Hampshire',
        unitId: 183044,
        count: 102,
      },
      {
        value: 'Swarthmore College',
        unitId: 216287,
        count: 181,
      },
      {
        value: 'Clarkson University',
        unitId: 190044,
        count: 64,
      },
      {
        value: 'University of North Carolina, Chapel Hill',
        unitId: 199120,
        count: 307,
      },
      {
        value: 'University of Idaho',
        unitId: 142285,
        count: 104,
      },
      {
        value: 'Ohio University',
        unitId: 204857,
        count: 439,
      },
      {
        value: 'Temple University',
        unitId: 216339,
        count: 572,
      },
      {
        value: 'University of Toledo',
        unitId: 206084,
        count: 278,
      },
      {
        value: 'Denison University',
        unitId: 202523,
        count: 219,
      },
      {
        value: 'Texas Tech University',
        unitId: 229115,
        count: 322,
      },
      {
        value: 'University of Alabama, Tuscaloosa',
        unitId: 100751,
        count: 128,
      },
      {
        value: 'Rowan University',
        unitId: 184782,
        count: 105,
      },
      {
        value: 'San Diego State University',
        unitId: 122409,
        count: 219,
      },
      {
        value: 'University of Delaware',
        unitId: 130943,
        count: 315,
      },
      {
        value: 'Bard College',
        unitId: 189088,
        count: 106,
      },
      {
        value: 'University of California, Irvine',
        unitId: 110653,
        count: 711,
      },
      {
        value: 'Utah State University',
        unitId: 230728,
        count: 155,
      },
      {
        value: 'Texas State University',
        unitId: 228459,
        count: 230,
      },
      {
        value: 'University of Illinois, Chicago',
        unitId: 145600,
        count: 263,
      },
      {
        value: 'Wake Forest University',
        unitId: 199847,
        count: 98,
      },
      {
        value: 'University of California, Merced',
        unitId: 445188,
        count: 313,
      },
      {
        value: 'Occidental College',
        unitId: 120254,
        count: 101,
      },
      {
        value: 'Boise State University',
        unitId: 142115,
        count: 369,
      },
      {
        value: 'Missouri State University',
        unitId: 179566,
        count: 303,
      },
      {
        value: 'Knox College',
        unitId: 146427,
        count: 103,
      },
      {
        value: 'Montana State University',
        unitId: 180461,
        count: 104,
      },
      {
        value: 'Carleton College',
        unitId: 173258,
        count: 102,
      },
      {
        value: 'California State University, Los Angeles',
        unitId: 110592,
        count: 105,
      },
      {
        value: 'Iowa State University',
        unitId: 153603,
        count: 504,
      },
      {
        value: 'University of Texas, San Antonio',
        unitId: 229027,
        count: 180,
      },
      {
        value: 'Eastern Michigan University',
        unitId: 169798,
        count: 314,
      },
      {
        value: 'Kenyon College',
        unitId: 203535,
        count: 106,
      },
      {
        value: 'Colorado State University',
        unitId: 126818,
        count: 435,
      },
      {
        value: 'Trinity College',
        unitId: 130590,
        count: 104,
      },
      {
        value: 'University of California, Santa Barbara',
        unitId: 110705,
        count: 231,
      },
      {
        value: 'New Jersey Institute of Technology',
        unitId: 185828,
        count: 156,
      },
      {
        value: 'University of Tennessee',
        unitId: 221759,
        count: 275,
      },
      {
        value: 'Hamilton College',
        unitId: 191515,
        count: 106,
      },
      {
        value: 'West Virginia University',
        unitId: 238032,
        count: 377,
      },
      {
        value: 'University of Colorado, Denver',
        unitId: 126562,
        count: 471,
      },
      {
        value: 'Bowdoin College',
        unitId: 161004,
        count: 102,
      },
      {
        value: 'Illinois State University',
        unitId: 145813,
        count: 423,
      },
      {
        value: 'University of Wisconsin, Eau Claire',
        unitId: 240268,
        count: 300,
      },
      {
        value: 'University of Minnesota',
        unitId: 174066,
        count: 143,
      },
      {
        value: 'University of Maine',
        unitId: 161253,
        count: 104,
      },
      {
        value: 'University of Illinois, Urbana-Champaign',
        unitId: 145637,
        count: 440,
      },
      {
        value: 'University of Rhode Island',
        unitId: 217484,
        count: 290,
      },
      {
        value: 'University of Massachusetts',
        unitId: 166629,
        count: 218,
      },
      {
        value: 'University of Arkansas',
        unitId: 106397,
        count: 462,
      },
      {
        value: 'Binghamton University',
        unitId: 196079,
        count: 407,
      },
      {
        value: 'University of Nevada, Las Vegas',
        unitId: 182281,
        count: 150,
      },
      {
        value: 'Colby College',
        unitId: 161086,
        count: 114,
      },
      {
        value: 'California Institute of Technology',
        unitId: 110404,
        count: 92,
      },
      {
        value: 'Lehigh University',
        unitId: 213543,
        count: 102,
      },
      {
        value: 'University of California, Riverside',
        unitId: 110671,
        count: 180,
      },
      {
        value: 'Dakota State University',
        unitId: 219082,
        count: 106,
      },
      {
        value: 'Oberlin College',
        unitId: 204501,
        count: 164,
      },
      {
        value: 'Virginia Tech University',
        unitId: 233921,
        count: 202,
      },
      {
        value: 'University of Nevada, Reno',
        unitId: 182290,
        count: 108,
      },
      {
        value: 'Franklin and Marshall College',
        unitId: 212577,
        count: 81,
      },
      {
        value: 'Johns Hopkins University',
        unitId: 162928,
        count: 103,
      },
      {
        value: 'California Polytechnic State University',
        unitId: 110422,
        count: 101,
      },
      {
        value: 'University of Wyoming',
        unitId: 240727,
        count: 103,
      },
      {
        value: 'University of California, Santa Cruz',
        unitId: 110714,
        count: 426,
      },
      {
        value: 'Scripps College',
        unitId: 123165,
        count: 96,
      },
      {
        value: 'Amherst College',
        unitId: 164465,
        count: 173,
      },
      {
        value: 'North Dakota State University',
        unitId: 200332,
        count: 309,
      },
      {
        value: 'Bucknell University',
        unitId: 211291,
        count: 220,
      },
      {
        value: 'Davidson College',
        unitId: 198385,
        count: 106,
      },
      {
        value: 'Illinois Institute of Technology',
        unitId: 145725,
        count: 104,
      },
      {
        value: 'University of Missouri, Columbia',
        unitId: 178396,
        count: 530,
      },
      {
        value: 'Texas A&M University',
        unitId: 228723,
        count: 490,
      },
      {
        value: 'University of Alaska',
        unitId: 102553,
        count: 99,
      },
      {
        value: 'Stony Brook University',
        unitId: 196097,
        count: 234,
      },
      {
        value: 'University of California, San Diego',
        unitId: 110680,
        count: 336,
      },
      {
        value: 'Santa Clara University',
        unitId: 122931,
        count: 102,
      },
      {
        value: 'Stevens Institute of Technology',
        unitId: 186867,
        count: 282,
      },
      {
        value: 'Southern Methodist University',
        unitId: 228246,
        count: 122,
      },
      {
        value: 'University of Rochester',
        unitId: 195030,
        count: 101,
      },
      {
        value: 'Southern Illinois University, Carbondale',
        unitId: 149222,
        count: 101,
      },
      {
        value: 'Washington State University',
        unitId: 236939,
        count: 417,
      },
      {
        value: 'Vanderbilt University',
        unitId: 221999,
        count: 186,
      },
      {
        value: 'University of Georgia',
        unitId: 139959,
        count: 320,
      },
      {
        value: 'Wellesley College',
        unitId: 168218,
        count: 104,
      },
      {
        value: 'University of Texas, Arlington',
        unitId: 228769,
        count: 138,
      },
      {
        value: 'Creighton University',
        unitId: 181002,
        count: 101,
      },
      {
        value: 'Drexel University',
        unitId: 212054,
        count: 422,
      },
      {
        value: 'Berea College',
        unitId: 156295,
        count: 103,
      },
      {
        value: 'Bates College',
        unitId: 160977,
        count: 105,
      },
      {
        value: 'Bowling Green State University',
        unitId: 201441,
        count: 285,
      },
      {
        value: 'University of Nebraska',
        unitId: 181464,
        count: 368,
      },
      {
        value: 'University of San Francisco',
        unitId: 122612,
        count: 104,
      },
      {
        value: 'Skidmore College',
        unitId: 195526,
        count: 101,
      },
      {
        value: 'Wesleyan University',
        unitId: 130697,
        count: 126,
      },
      {
        value: 'Harvey Mudd College',
        unitId: 115409,
        count: 109,
      },
      {
        value: 'University of Texas, Dallas',
        unitId: 228787,
        count: 405,
      },
      {
        value: 'Emory University',
        unitId: 139658,
        count: 291,
      },
      {
        value: 'Yale University',
        unitId: 130794,
        count: 375,
      },
      {
        value: 'Williams College',
        unitId: 168342,
        count: 102,
      },
      {
        value: 'California State University, Fresno',
        unitId: 110556,
        count: 258,
      },
      {
        value: 'Wheaton College',
        unitId: 168281,
        count: 265,
      },
      {
        value: 'University of Cincinnati',
        unitId: 201885,
        count: 175,
      },
      {
        value: 'Vassar College',
        unitId: 197133,
        count: 105,
      },
      {
        value: 'George Washington University',
        unitId: 131469,
        count: 250,
      },
      {
        value: 'Boston University',
        unitId: 164988,
        count: 143,
      },
      {
        value: 'Montclair State University',
        unitId: 185590,
        count: 102,
      },
      {
        value: 'Massachusetts Institute of Technology',
        unitId: 166683,
        count: 275,
      },
      {
        value: 'Rice University',
        unitId: 227757,
        count: 248,
      },
      {
        value: 'University of Notre Dame',
        unitId: 152080,
        count: 452,
      },
      {
        value: 'San Jose State University',
        unitId: 122755,
        count: 178,
      },
      {
        value: 'University at Buffalo',
        unitId: 196088,
        count: 343,
      },
      {
        value: 'University of Florida',
        unitId: 134130,
        count: 136,
      },
      {
        value: 'Southern Illinois University, Edwardsville',
        unitId: 149231,
        count: 344,
      },
      {
        value: 'University of Kentucky',
        unitId: 157085,
        count: 172,
      },
      {
        value: 'Rensselaer Polytechnic Institute',
        unitId: 194824,
        count: 233,
      },
      {
        value: 'Ohio State University',
        unitId: 204796,
        count: 430,
      },
      {
        value: 'Michigan State University',
        unitId: 171100,
        count: 157,
      },
      {
        value: 'Colorado College',
        unitId: 126678,
        count: 102,
      },
      {
        value: 'Middlebury College',
        unitId: 230959,
        count: 106,
      },
      {
        value: 'Northeastern University',
        unitId: 167358,
        count: 328,
      },
      {
        value: 'Smith College',
        unitId: 167835,
        count: 94,
      },
      {
        value: 'Pitzer College',
        unitId: 121257,
        count: 100,
      },
      {
        value: 'Chapman University',
        unitId: 111948,
        count: 102,
      },
      {
        value: 'Tufts University',
        unitId: 168148,
        count: 373,
      },
      {
        value: 'University of Central Florida',
        unitId: 132903,
        count: 319,
      },
      {
        value: 'Macalester College',
        unitId: 173902,
        count: 103,
      },
      {
        value: 'Villanova University',
        unitId: 216597,
        count: 209,
      },
      {
        value: 'University of Michigan',
        unitId: 170976,
        count: 505,
      },
      {
        value: 'Washington University in St Louis',
        unitId: 179867,
        count: 463,
      },
      {
        value: 'University of Miami',
        unitId: 135726,
        count: 116,
      },
      {
        value: 'Boston College',
        unitId: 164924,
        count: 104,
      },
      {
        value: 'Haverford College',
        unitId: 212911,
        count: 154,
      },
      {
        value: 'Grinnell College',
        unitId: 153384,
        count: 101,
      },
      {
        value: 'University of Dayton',
        unitId: 202480,
        count: 176,
      },
      {
        value: 'Western Michigan University',
        unitId: 172699,
        count: 100,
      },
      {
        value: 'Portland State University',
        unitId: 209807,
        count: 95,
      },
      {
        value: 'Case Western Reserve University',
        unitId: 201645,
        count: 442,
      },
      {
        value: 'University of Connecticut',
        unitId: 129020,
        count: 382,
      },
      {
        value: 'Gettysburg College',
        unitId: 212674,
        count: 102,
      },
      {
        value: 'Rutgers University',
        unitId: 186380,
        count: 468,
      },
      {
        value: 'Louisiana State University',
        unitId: 159391,
        count: 354,
      },
      {
        value: 'University of Oregon',
        unitId: 209551,
        count: 105,
      },
      {
        value: 'DePaul University',
        unitId: 144740,
        count: 138,
      },
      {
        value: 'Connecticut College',
        unitId: 128902,
        count: 125,
      },
      {
        value: 'University of Kansas',
        unitId: 155317,
        count: 307,
      },
      {
        value: 'College of Charleston',
        unitId: 217819,
        count: 101,
      },
      {
        value: 'University of North Texas',
        unitId: 227216,
        count: 211,
      },
      {
        value: 'SUNY College at Geneseo',
        unitId: 196167,
        count: 86,
      },
      {
        value: 'Mount Holyoke College',
        unitId: 166939,
        count: 107,
      },
      {
        value: 'University of Pittsburgh',
        unitId: 215293,
        count: 149,
      },
      {
        value: 'Loyola University, Chicago',
        unitId: 146719,
        count: 138,
      },
      {
        value: 'University of Denver',
        unitId: 127060,
        count: 71,
      },
      {
        value: 'Colgate University',
        unitId: 190099,
        count: 103,
      },
      {
        value: 'SUNY at Albany',
        unitId: 196060,
        count: 104,
      },
      {
        value: 'Lafayette College',
        unitId: 213385,
        count: 233,
      },
      {
        value: 'Clark University',
        unitId: 165334,
        count: 102,
      },
      {
        value: 'Cornell University',
        unitId: 190415,
        count: 518,
      },
      {
        value: 'University of Iowa',
        unitId: 153658,
        count: 133,
      },
      {
        value: 'Tulane University',
        unitId: 160755,
        count: 227,
      },
      {
        value: 'Stanford University',
        unitId: 243744,
        count: 248,
      },
      {
        value: 'University of New Mexico',
        unitId: 187985,
        count: 103,
      },
      {
        value: 'University of California, Los Angeles',
        unitId: 110662,
        count: 264,
      },
      {
        value: 'Furman University',
        unitId: 218070,
        count: 114,
      },
      {
        value: 'Duquesne University',
        unitId: 212106,
        count: 122,
      },
      {
        value: 'Princeton University',
        unitId: 186131,
        count: 312,
      },
      {
        value: 'Dartmouth College',
        unitId: 182670,
        count: 235,
      },
      {
        value: 'University of California, Berkeley',
        unitId: 110635,
        count: 517,
      },
      {
        value: 'University of Washington',
        unitId: 236948,
        count: 184,
      },
      {
        value: 'University of Wisconsin, Madison',
        unitId: 240444,
        count: 529,
      },
      {
        value: 'Pennsylvania State University',
        unitId: 214777,
        count: 513,
      },
      {
        value: 'Brown University',
        unitId: 217156,
        count: 119,
      },
      {
        value: 'University of Houston',
        unitId: 225511,
        count: 371,
      },
      {
        value: 'Brandeis University',
        unitId: 165015,
        count: 266,
      },
      {
        value: 'Central Michigan University',
        unitId: 169248,
        count: 174,
      },
      {
        value: 'University of Vermont',
        unitId: 231174,
        count: 337,
      },
      {
        value: 'Fordham University',
        unitId: 191241,
        count: 110,
      },
      {
        value: 'Marquette University',
        unitId: 239105,
        count: 106,
      },
      {
        value: 'Howard University',
        unitId: 131520,
        count: 223,
      },
      {
        value: 'American University',
        unitId: 131159,
        count: 105,
      },
      {
        value: 'Northwestern University',
        unitId: 147767,
        count: 283,
      },
      {
        value: 'University of California, Davis',
        unitId: 110644,
        count: 140,
      },
      {
        value: 'Georgetown University',
        unitId: 131496,
        count: 102,
      },
      {
        value: 'University of Utah',
        unitId: 230764,
        count: 101,
      },
      {
        value: 'Pomona College',
        unitId: 121345,
        count: 106,
      },
      {
        value: 'Indiana University',
        unitId: 151351,
        count: 573,
      },
      {
        value: 'University of Texas, Austin',
        unitId: 228778,
        count: 326,
      },
      {
        value: 'University of Southern California',
        unitId: 123961,
        count: 180,
      },
      {
        value: 'Syracuse University',
        unitId: 196413,
        count: 150,
      },
      {
        value: 'Barnard College',
        unitId: 189097,
        count: 129,
      },
      {
        value: 'University of Pennsylvania',
        unitId: 215062,
        count: 248,
      },
      {
        value: 'New York University',
        unitId: 193900,
        count: 139,
      },
      {
        value: 'Columbia University',
        unitId: 190150,
        count: 389,
      },
      {
        value: 'Harvard University',
        unitId: 166027,
        count: 100,
      },
      {
        value: 'Baylor University',
        unitId: 223232,
        count: 367,
      },
      {
        value: 'Brigham Young University',
        unitId: 230038,
        count: 492,
      },
      {
        value: 'Hillsdale College',
        unitId: 170286,
        count: 101,
      },
      {
        value: 'Liberty University',
        unitId: 232557,
        count: 105,
      },
      {
        value: 'Pepperdine University',
        unitId: 121150,
        count: 102,
      },
      {
        value: 'Saint Louis University',
        unitId: 179159,
        count: 284,
      },
    ],
  ],
  demographics: ['school'],
}

export default schoolTotalCountData
