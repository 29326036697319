import React from 'react'
import Navigation from '../Components/Navigation/Navigation'

const MainLayout = ({ children }) => {
  return (
    <React.Fragment>
      <Navigation />

      <main>{children}</main>
      {/* {!window.location.href.includes('methodology') && (
        <section id="hs-contact-all" className="section has-background-success mt-6" style={{ display: window.location.href.includes('school') ? 'none' : 'block'}}>
          <div
            className="container py-6"
            style={{ maxWidth: 640, marginLeft: 'auto', marginRight: 'auto' }}
          >
            <h1 className="title has-text-light is-center has-text-centered">Stay In The Know.</h1>
            <h4 className="subtitle has-text-light is-center has-text-centered">
              Get updates from the College Free Speech team including newsletters, rankings
              announcements, and new features.
            </h4>

            <div id="hubspot_form"  className="px-5 py-5" />
          </div>
        </section>
      )} */}
    </React.Fragment>
  )
}

export default MainLayout
