import React, { useEffect, useState } from 'react'
import { Switch, Route, useLocation, Redirect } from 'react-router-dom'
import ReactGA from 'react-ga'

// Components
import MainLayout from './Layout/MainLayout'

// Styles
import './Styles/Main.scss'

// Pages
import Rank from './Pages/Rank'
import School from './Pages/School'
import Landing from './Pages/Landing'
import Compare from './Pages/Compare'
import Methodology from './Pages/Methodology'
import ExploreTheData from './Pages/ExploreTheData'
import ViewData from './Pages/ViewData'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMailBulk, faUser, faUserShield, faSitemap } from '@fortawesome/free-solid-svg-icons'

import ls from 'local-storage'
import { createNewContact } from './api/contact'

ReactGA.initialize('UA-121938112-4') // Add your ID

const usePageViews = () => {
  let location = useLocation()
  useEffect(() => {
    ReactGA.set({ page: location.pathname })
    ReactGA.pageview(location.pathname)

    const script = document.createElement('script')
    script.src = 'https://js.hsforms.net/forms/v2.js'
    script.defer = true
    document.body.appendChild(script)

    script.addEventListener('load', () => {
      if (window.hbspt && document.querySelector('#hubspot_form').childElementCount === 0) {
        window.hbspt.forms.create({
          portalId: '5666503',
          formId: 'de9b91ce-3dc7-4497-abc0-5d9b60d2f814',
          target: '#hubspot_form',
        })
      }
    })
  }, [location])
}

const App = () => {
  usePageViews()

  const [contactInformation, setContactInformation] = useState({
    email: '',
    firstName: '',
    lastName: '',
    organization: '',
  })

  const [showModal, setShowModal] = useState(false)
  const [opacity, setOpacity] = useState(0)

  const handleUpdateContactInformation = e => {
    setContactInformation({
      ...contactInformation,
      [e.target.id]: e.target.value,
    })
  }

  const handleFormSubmission = async () => {
    let contact = await createNewContact(contactInformation)
    ReactGA.event({
      category: 'Contact Modal',
      action: 'Submitted contact information',
      label: 'Contact Modal Submitted',
    })
    ls('cp_gave_contact', true)
    setOpacity(0)
    setTimeout(() => {
      setShowModal(false)
    }, 800)
  }

  const isValidEmail = email => {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)
  }

  useEffect(() => {
    if (
      !ls('cp_gave_contact') ||
      ls('cp_gave_contact') === 'false' ||
      ls('cp_gave_contact') === 'undefined'
    ) {
      ls('cp_gave_contact', false)
      

      // check query parameters for ?nocontact=true
      let urlParams = new URLSearchParams(window.location.search)
      if (urlParams.get('nocontact') === 'true') {
        ls('cp_gave_contact', true)
        return
      }

      ReactGA.event({
        category: 'Contact Modal',
        action: 'Visited page without providing contact information',
        label: 'Contact Modal Opened',
      })

      setShowModal(true)
      setTimeout(() => {
        setOpacity(1)
      }, 50)
    }
  }, [])
  // Check if cp_gave_contact localstorage variable is set

  return (
    <>
      {showModal && (
        <div
          style={{
            height: '100vh',
            width: '100vw',
            position: 'fixed',
            top: 0,
            left: 0,
            backgroundColor: 'rgba(0,0,0,0.4)',
            zIndex: 100000,
            overflow: 'hidden',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            transition: '800ms',
            opacity: opacity,
          }}
        >
          <div
            style={{
              backgroundColor: 'white',
              borderRadius: '1rem',
              width: '35rem',
              //height: '',
              maxHeight: '80vh',
              maxWidth: '90vw',
              padding: '2rem',
              transform: `scale(${opacity ? 1 : 0.9})`,
              transition: opacity ? '500ms' : '800ms',
              overflowY: 'auto',
            }}
          >
            <h4 className="has-text-weight-bold mt-0 mb-0" style={{ fontSize: '1.25rem' }}>
              2025 College Free Speech Rankings
            </h4>
            <p>Please provide your contact information to gain access to the rankings.</p>
            <div className="columns mt-4">
              <div className="field column is-rounded mb-0">
                <label htmlFor="firstName" style={{ fontSize: '0.85rem' }}>
                  First Name
                </label>
                <div className="control has-icons-left">
                  <input
                    id="firstName"
                    value={contactInformation.firstName}
                    onChange={handleUpdateContactInformation}
                    className="input"
                    type="text"
                    placeholder="First Name"
                  />
                  <span className="icon is-small is-left">
                    <FontAwesomeIcon icon={faUser} />
                  </span>
                </div>
              </div>
              <div className="field column is-rounded mb-0">
                <label htmlFor="lastName" style={{ fontSize: '0.85rem' }}>
                  Last Name
                </label>
                <div className="control has-icons-left">
                  <input
                    id="lastName"
                    value={contactInformation.lastName}
                    onChange={handleUpdateContactInformation}
                    className="input"
                    type="text"
                    placeholder="Last Name"
                  />
                  <span className="icon is-small is-left">
                    <FontAwesomeIcon icon={faUserShield} />
                  </span>
                </div>
              </div>
            </div>
            <div className="field is-rounded mb-5 mt-0">
              <label htmlFor="organization" style={{ fontSize: '0.85rem' }}>
                Organization
              </label>
              <div className="control has-icons-left">
                <input
                  id="organization"
                  value={contactInformation.organization}
                  onChange={handleUpdateContactInformation}
                  className="input"
                  type="text"
                  placeholder="Organization"
                />
                <span className="icon is-small is-left">
                  <FontAwesomeIcon icon={faSitemap} />
                </span>
              </div>
            </div>
            <div className="field is-rounded mb-0">
              <label htmlFor="email" style={{ fontSize: '0.85rem' }}>
                Email Address*
              </label>
              <div
                className="control has-icons-left"
                style={
                  contactInformation.email.length < 5 || isValidEmail(contactInformation.email)
                    ? {}
                    : {
                        border: '1px solid red',
                        borderRadius: '5px',
                      }
                }
              >
                <input
                  id="email"
                  value={contactInformation.email}
                  onChange={handleUpdateContactInformation}
                  className="input"
                  type="text"
                  placeholder="Email Address"
                />
                <span className="icon is-small is-left">
                  <FontAwesomeIcon icon={faMailBulk} />
                </span>
              </div>
            </div>

            <div
              style={{ width: '100%', display: 'flex', justifyContent: 'center' }}
              className="hero-button button is-success mt-5 has-text-weight-bold"
              disabled={!isValidEmail(contactInformation.email)}
              onClick={() => isValidEmail(contactInformation.email) && handleFormSubmission()}
            >
              Submit
            </div>
            <p className="mt-5" style={{ fontSize: '0.8rem' }}>
              <small>
                By providing your contact information, you consent to receive communications,
                including newsletters, rankings announcements, and new features, from FIRE and College Pulse. Your information will be used in accordance with the{' '}
                <a className="has-underline" href="https://collegepulse.com/terms" target="_blank">
                  College Pulse privacy policy
                </a>{' '}
                and{' '}
                <a
                  href="https://www.thefire.org/privacy-policy"
                  target="_blank"
                  className="has-underline"
                >
                  Fire privacy policy.
                </a>
                <br />* = required
              </small>
            </p>
          </div>
        </div>
      )}
      <div
        style={
          showModal
            ? {
                filter: opacity ? 'blur(2px)' : '',
                pointerEvents: 'none',
                overflow: opacity ? 'hidden' : 'auto',
                position: 'relative',
                height: '100vh',
                transition: '1000ms',
              }
            : {}
        }
      >
        <Switch>
          <Route exact path="/">
            {/*
            Hero
            As Seen On
            Our Approach
            */}
            <Landing />
          </Route>
          <MainLayout>
            <Switch>
              <Route exact path="/view-data/">
                <ViewData />
              </Route>

              <Route exact path="/rank/">
                {/*
              Nav
              Sidebar Sort
              Table
              */}
                <Rank />
              </Route>
              <Route path="/rank/explore">
                {/*
              Nav
              Sidebar Sort
              Table
              */}
                <ExploreTheData />
              </Route>
              <Route path="/rank/school/:schoolID">
                {/*
              Nav
              School Overview Component
              School Description + social
              Quotes
              */}
                <div>
                  <School />
                </div>
              </Route>
              <Route path="/rank/map">
                {/*
              Nav
              Sidebar Sort
              UE Map
              */}
                Map
              </Route>
              <Route path="/rank/map/:schoolID">
                {/*
              Nav
              School Overview Component
              UE Map
              */}
                <School />
              </Route>
              <Route path="/rank/compare">
                <Compare />
              </Route>
              <Route path="/rank/methodology">
                <Methodology />
              </Route>
              <Route
                path="*"
                exact
                render={() => (
                  <Redirect
                    to={{
                      pathname: '/',
                    }}
                  />
                )}
              />
            </Switch>
          </MainLayout>
        </Switch>
      </div>
    </>
  )
}

export default App

// export default () => {
//   return (
//     <div
//       style={{
//         display: 'flex',
//         justifyContent: 'center',
//         alignItems: 'center',
//         backgroundColor: '#00000020',
//         height: '100vh',
//         overflow: 'hidden!important',
//       }}
//     >
//       <div
//         className="p-5"
//         style={{
//           backgroundColor: 'white',
//           borderRadius: 6,
//           boxShadow: '0 0 10px rgba(0,0,0,0.2)',
//         }}
//         id="card"
//       >
//         <div
//           className="logos is-relative flex"
//           style={{ justifyContent: 'center', paddingRight: '2rem' }}
//         >
//           <a target="_blank" rel="noreferrer" href="https://www.collegepulse.com">
//             <img src="/assets/cp.svg" alt="College Pulse" width="170" className="ml-4 logo-cp" />
//           </a>

//           <a target="_blank" rel="noreferrer" href="https://www.thefire.org/">
//             <img
//               src="/assets/fire.svg"
//               alt="Fire Organization"
//               width="80"
//               className="ml-6 logo-fire"
//               style={{ marginTop: -2 }}
//             />
//           </a>
//         </div>
//         <div
//           style={{ height: 2, backgroundColor: 'gray', marginTop: '1rem', marginBottom: '1rem' }}
//         ></div>
//         <div>
//           <strong>We'll be back soon!</strong>
//           <br />
//           We're launching this years College Free Speech dashboard. Check back soon!
//         </div>
//       </div>
//     </div>
//   )
// }
