import React, { useState, useEffect, useRef } from 'react'
import { HashLink as Link } from 'react-router-hash-link'
import schoolTotalCountData from '../Data/schoolTotalCountData'

// Components
import ShareComponent from '../Components/ShareComponent/ShareComponent'

// Styles
import '../Styles/Methodology.scss'

const Methodology = () => {
  // Contact Form
  useEffect(() => {
    const script = document.createElement('script')
    script.src = 'https://js.hsforms.net/forms/v2.js'
    script.defer = true
    document.body.appendChild(script)

    script.addEventListener('load', () => {
      if (window.hbspt) {
        window.hbspt.forms.create({
          portalId: '5666503',
          formId: '740e0269-b179-4237-b5b2-df40555924a6',
          target: '#contact',
        })
      }
    })
  }, [])

  const [activeCategory, setActiveCategory] = useState('data')

  // Elements
  const dataElement = useRef(null)
  const rankingsElement = useRef(null)
  const teamElement = useRef(null)
  const schoolsElement = useRef(null)
  const contactElement = useRef(null)

  const handleScrollMethodology = () => {
    if (teamElement.current && rankingsElement.current && dataElement.current) {
      if (contactElement.current.getBoundingClientRect().top <= window.innerHeight / 2) {
        setActiveCategory('contact')
        return
      }

      if (schoolsElement.current.getBoundingClientRect().top <= window.innerHeight / 2) {
        setActiveCategory('schools')
        return
      }

      if (teamElement.current.getBoundingClientRect().top <= window.innerHeight / 2) {
        setActiveCategory('team')
        return
      }

      if (rankingsElement.current.getBoundingClientRect().top <= window.innerHeight / 2) {
        setActiveCategory('rankings')
        return
      }

      if (dataElement.current.getBoundingClientRect().top <= window.innerHeight / 2) {
        setActiveCategory('data')
        return
      }
    }
  }
  useEffect(() => {
    window.addEventListener('scroll', handleScrollMethodology)

    return () => {
      window.removeEventListener('scroll', () => handleScrollMethodology)
    }
  }, [])

  return (
    <>
      <section className="section has-background-white-ter mx-0 px-0 py-3">
        <div className="container">
          <div className="is-flex">
            <div
              className="header-section-ill no-mobile"
              style={{ width: '100px!important', flexShrink: 0 }}
            >
              <img
                style={{ width: '100px!important', height: 'auto' }}
                alt="Graphic"
                src="/assets/methodology.svg"
              />
            </div>
            <div className="is-center header-section-text-column">
              <div>
                <h1 className="title is-4 mb-2 mt-5">Methodology</h1>
                <p
                  className="header-section-text"
                  dangerouslySetInnerHTML={{
                    __html:
                      'The College Free Speech Rankings are based on rigorous analysis of five distinct aspects of the culture for free speech on college campuses for all students surveyed. The ranking compares 254 top colleges in the U.S. Higher scores indicate more open climates for free speech. Read below for information about the data and how this ranking was calculated.',
                  }}
                />
                <div className="level">
                  <div className="level-left"></div>
                  <div className="level-right">
                    <ShareComponent />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="section has-text-primary px-4">
        <div className="container">
          <div className="columns is-relative">
            <div className="column is-3 content is-relative  no-mobile">
              <div style={{ position: 'sticky', top: 5 }}>
                <div
                  className={`px-2 mb-1 py-3 ${
                    activeCategory === 'data'
                      ? 'has-background-success-light has-text-weight-bold'
                      : ''
                  }`}
                >
                  <Link smooth to="#data">
                    The Data
                  </Link>
                </div>
                <div
                  className={`px-2 mb-1 py-3 ${
                    activeCategory === 'rankings'
                      ? 'has-background-success-light has-text-weight-bold'
                      : ''
                  }`}
                >
                  <Link smooth to="#rankings">
                    The Rankings
                  </Link>
                </div>

                <div
                  className={`px-2 mb-1 py-3 ${
                    activeCategory === 'team'
                      ? 'has-background-success-light has-text-weight-bold'
                      : ''
                  }`}
                >
                  <Link smooth to="#team">
                    The Team
                  </Link>
                </div>

                <div
                  className={`px-2 mb-1 py-3 ${
                    activeCategory === 'schools'
                      ? 'has-background-success-light has-text-weight-bold'
                      : ''
                  }`}
                >
                  <Link smooth to="#schools-section">
                    The Schools
                  </Link>
                </div>

                <div
                  className={`px-2 mb-1 py-3 ${
                    activeCategory === 'contact'
                      ? 'has-background-success-light has-text-weight-bold'
                      : ''
                  }`}
                >
                  <Link smooth to="#contact-section">
                    Contact Us
                  </Link>
                </div>
              </div>
            </div>
            <div className="column pl-5-desktop content is-7">
              <div className="mb-5 pt-5" ref={dataElement} id="data">
                <h1>The Data</h1>

                <h4>Where does the data come from?</h4>
                <p>
                  The College Free Speech Rankings survey was developed by FIRE and administered by
                  College Pulse. No donors to the project took part in designing or conducting the
                  survey. The survey was fielded from January 25 through June 17, 2024. These data
                  come from a sample of 58,807 undergraduates who were then enrolled full-time in
                  four-year degree programs at one of a list of 258 colleges and universities in the
                  United States. The margin of error for the U.S. undergraduate population is +/-
                  0.4 of a percentage point, and the margin of error for college student
                  sub-demographics ranges from 2-5 percentage points.
                </p>

                <h4>How were the students recruited?</h4>
                <p>
                  The initial sample was drawn from{' '}
                  <em>College Pulse’s American College Student Panel™</em>, which includes more
                  than 850,000 verified undergraduate students and recent alumni from schools within
                  a range of more than 1,500 two- and four-year colleges and universities in all 50
                  states. Panel members were recruited by a number of methods to help ensure student
                  diversity in the panel population. These methods include web advertising,
                  permission-based email campaigns, and partnerships with university-affiliated
                  organizations. To ensure the panel reflects the diverse backgrounds and
                  experiences of the American college population, College Pulse recruited panelists
                  from a wide variety of institutions. The panel includes students attending large
                  public universities, small private colleges, online universities, historically
                  Black colleges such as Howard University, women’s colleges such as Smith College,
                  and religiously-affiliated colleges such as Brigham Young University.
                </p>

                <h4>How does College Pulse ensure response quality?</h4>
                <p>
                  College Pulse uses a two-stage validation process to ensure that all its surveys
                  include only students currently enrolled in two-year or four-year colleges or
                  universities. Students are required to provide an “.edu” email address to join the
                  panel and, for this survey, had to acknowledge that they are currently enrolled
                  full-time in a four-year degree program. All invitations to complete surveys were
                  sent using the student’s “.edu” email address or through a notification in the
                  College Pulse app, available on iOS and Android platforms.
                </p>

                <h4>
                  How does College Pulse ensure its surveys reflect the views of college students
                  nationally?
                </h4>
                <p>
                  College Pulse applies a post-stratification adjustment based on demographic
                  distributions from multiple data sources, including the Current Population Survey
                  (CPS), the National Postsecondary Student Aid Study (NPSAS), and the Integrated
                  Postsecondary Education Data System (IPEDS). The “weight” rebalances the sample
                  based on a number of important benchmark attributes, such as race, gender, class
                  year, voter registration status, and financial aid status. The sample weighting is
                  accomplished using an iterative proportional fitting (IFP) process that
                  simultaneously balances the distributions of all variables to produce a
                  representative sample of four year undergraduate students in the United States.
                </p>
                <p>
                  This year College Pulse introduced a similar post-stratification adjustment based
                  on demographic distributions from multiple data sources, including the Current
                  Population Survey (CPS), the National Postsecondary Student Aid Study (NPSAS), and
                  the Integrated Postsecondary Education Data System (IPEDS). The “school universe
                  weight” rebalances the sample based on a number of important benchmark attributes,
                  such as race, gender, class year, voter registration status, and financial aid
                  status. The sample weighting is accomplished using an iterative proportional
                  fitting (IFP) process that simultaneously balances the distributions of all
                  variables to produce a representative sample of four year undergraduate students
                  from the 257 colleges and universities surveyed.
                </p>
                <p>
                  College Pulse also applies a post-stratification adjustment based on demographic
                  distributions from the Current Population Survey (CPS), the National Postsecondary
                  Student Aid Study (NPSAS), and the Integrated Postsecondary Education Data System
                  (IPEDS). This “school weight” rebalances the sample from each individual school
                  surveyed based on a number of important benchmark attributes, such as race,
                  gender, class year, voter registration status, and financial aid status. The
                  sample weighting is accomplished using an iterative proportional fitting (IFP)
                  process that simultaneously balances the distributions of all variables to produce
                  a representative sample of students at each individual school.
                </p>
                <p>
                  All weights are trimmed to prevent individual interviews from having too much
                  influence on the final results and to ensure over-sampled population groups do not
                  completely lose their voice.
                </p>
                <p>
                  The use of these weights in statistical analysis ensures that the demographic
                  characteristics of the sample closely approximate the demographic characteristics
                  of the target populations. Even with these adjustments, surveys may be subject to
                  error or bias due to question wording, context, and order effects.{' '}
                </p>
                <p>
                  For further information, please see:{' '}
                  <a
                    className="has-text-underline has-text-success"
                    rel="noreferrer"
                    target="_blank"
                    href="https://collegepulse.com/methodology"
                  >
                    https://collegepulse.com/methodology
                  </a>
                </p>
              </div>

              <div className="mb-5 pt-5" ref={rankingsElement} id="rankings">
                <h1>Free Speech Rankings</h1>
                <p>
                  The College Free Speech Rankings are based on a composite score of 14 components,
                  seven of which assess student perceptions of different aspects of the speech
                  climate on their campus. The other seven assess behavior by administrators,
                  faculty, and students regarding free expression on campus. Higher scores indicate
                  a better campus climate for free speech and expression.
                </p>

                <h2>
                  <em>Student Perceptions</em>
                </h2>
                <p>The student perception components include:</p>

                <ul>
                  <li>
                    <p>
                      <strong>Comfort Expressing Ideas: </strong>Students were asked how comfortable
                      they feel expressing their views on controversial topics in five different
                      campus settings (e.g., “in class,” or “in the dining hall”). Options ranged
                      from “very uncomfortable” to “very comfortable.” Responses were coded so that
                      higher scores indicate greater comfort expressing ideas. The maximum number of
                      points is 20.
                    </p>
                  </li>
                  <li>
                    <p>
                      <strong>Self-Censorship: </strong>Students were provided with a definition of
                      self-censorship and then asked how often they self-censored in three different
                      settings on campus (e.g., “in a classroom discussion”). Responses were coded
                      so that higher scores indicate self-censoring less often. The maximum number
                      of points is 15. The self-censorship component was introduced this year and is
                      a composite score of responses to the three questions that are presented after
                      self-censorship is defined. In previous years other questions were used to
                      measure self-censorship and they were factored into the “Comfort Expressing
                      Ideas” component.
                    </p>
                  </li>
                  <li>
                    <p>
                      <strong>Tolerance for Liberal Speakers: </strong>
                      Students were asked whether three speakers espousing views potentially
                      offensive to conservatives (e.g., “The police are just as racist as the Ku
                      Klux Klan.”) should be allowed on campus, regardless of whether they
                      personally agree with the speaker’s message. Options ranged from “definitely
                      should not allow this speaker” to “definitely should allow this speaker” and
                      were coded so that higher scores indicate more tolerance of the speaker (i.e.,
                      more support for allowing the speaker on campus). The maximum number of points
                      is 12.
                    </p>
                  </li>
                  <li>
                    <p>
                      <strong>Tolerance for Conservative Speakers: </strong>
                      Students were also asked whether three speakers espousing views potentially
                      offensive to liberals (e.g., “Black Lives Matter is a hate group”) should be
                      allowed on campus, regardless of whether they personally agree with the
                      speaker’s message. Scoring was performed in the same manner as it was for the
                      “Tolerance for Liberal Speakers” subcomponent, and the maximum number of
                      points is 12.
                    </p>
                  </li>
                  <li>
                    <p>
                      <strong>Disruptive Conduct: </strong>
                      Students were asked how acceptable it is to engage in different methods of
                      protest against a campus speaker, including “shouting down a speaker or trying
                      to prevent them from speaking on campus,” “blocking other students from
                      attending a campus speech,” and “using violence to stop a campus speech.”
                      Options ranged from “always acceptable” to “never acceptable” and were coded
                      so that higher scores indicate less acceptance of disruptive conduct. The
                      maximum number of points is 12.
                    </p>
                  </li>
                  <li>
                    <p>
                      <strong>Administrative Support: </strong>
                      Students were asked how clear it is their administration protects free speech
                      on campus and how likely the administration would be to defend a speaker’s
                      right to express their views if a controversy over speech occurred on campus.
                      For the administrative clarity question, options range from “not at all clear”
                      to “extremely clear,” and for the administrative controversy question, options
                      range from “not at all likely” to “extremely likely.” Options were coded so
                      that higher scores indicate greater clarity and a greater likelihood of
                      defending a speaker’s rights. The maximum number of points is 10.
                    </p>
                  </li>
                  <li>
                    <p>
                      <strong>Openness: </strong>
                      Finally, students were asked which of 20 issues (e.g., “abortion,” “freedom of
                      speech,” “gun control,” and “racial inequality”), if any, are difficult to
                      have open conversations about on campus. Responses were coded so that higher
                      scores indicate fewer issues being selected. The maximum number of points is
                      20.
                    </p>
                  </li>
                </ul>

                <p>
                  Two additional constructs, “Mean Tolerance” and “Tolerance Difference,” were
                  computed from the “Tolerance for Liberal/Conservative Speaker” components.
                  “Tolerance Difference” was calculated by subtracting “Tolerance for Conservative
                  Speakers” from “Tolerance for Liberal Speakers” and then taking the absolute value
                  (so that a bias in favor of either side would be treated the same).
                </p>

                <h2>
                  <em>Campus Behavioral Metrics</em>
                </h2>
                <p>
                  Schools received bonus points — described in more detail below — for unequivocally
                  supporting free expression in response to speech controversies by taking the
                  following actions indicative of a positive campus climate for free speech:
                </p>

                <ul>
                  <li>
                    <p>
                      Supporting free expression during a deplatforming campaign, as recorded in
                      FIRE’s Campus Deplatforming database.
                    </p>
                  </li>
                  <li>
                    <p>
                      Supporting a scholar whose speech rights were threatened during a free speech
                      controversy, as recorded in FIRE's Scholars Under Fire database.
                    </p>
                  </li>
                  <li>
                    <p>
                      Supporting students and student groups, as recorded in the 2025 College Free
                      Speech Rankings behavioral metrics documentation that is available online.
                    </p>
                  </li>
                </ul>
                <p>
                  Schools were penalized — described in more detail below — for taking the following
                  actions indicative of poor campus climate for free speech:
                </p>
                <ul>
                  <li>
                    <p>
                      Successfully deplatforming a speaker, as recorded in FIRE’s Campus
                      Deplatforming database.
                    </p>
                  </li>
                  <li>
                    <p>
                      Sanctioning a scholar (e.g., placing under investigation, suspending, or
                      terminating a scholar), as recorded in FIRE’s Scholars Under Fire database.
                    </p>
                  </li>
                  <li>
                    <p>
                      Sanctioning a student or student groups, as recorded in the 2025 College Free
                      Speech Rankings behavioral metrics documentation that is available online.
                    </p>
                  </li>
                </ul>
                <p>
                  To be included in this year’s rankings, an incident that resulted in a bonus or
                  penalty had to have been recorded by June 15, 2024, and had to have been fully
                  assessed by FIRE’s research staff, who determined whether the incident warranted
                  inclusion.
                </p>
                <p>
                  In response to the encampment protests, FIRE and College Pulse reopened the 2025
                  College Free Speech Rankings survey on any campus with an encampment. This allowed
                  us to collect survey data from students while the encampments were taking place.
                  That means that this year’s College Free Speech Rankings provide a treasure trove
                  of data on the evolving state of free expression at American colleges and
                  universities.
                </p>
                <p>
                  FIRE’s Spotlight ratings — our ratings of the written policies governing student
                  speech at nearly 500 institutions of higher education in the United States — also
                  factored into each school's overall score. Three substantive ratings are possible:
                  “red light,” “yellow light,” and “green light.” A “red light” rating indicates
                  that the institution has at least one policy that both clearly and substantially
                  restricts freedom of speech. A “yellow light” rating indicates that an institution
                  maintains at least one policy that places a clear restriction on a more limited
                  amount of protected expression, or one that, by virtue of vague wording, could too
                  easily be used to restrict protected expression. A “green light” rating indicates
                  that an institution maintains no policies that seriously threaten speech, although
                  this rating does not indicate whether a college actively supports free expression.
                </p>
                <p>
                  Finally, a fourth rating, “Warning,” is assigned to a private college or
                  university when its policies clearly and consistently state that it prioritizes
                  other values over a commitment to free speech. “Warning” schools, therefore, were
                  not ranked, and their overall scores are presented separately in this report.
                </p>
                <p>
                  For this year’s rankings, the cutoff date for assessing a school’s speech code
                  policies was June 15, 2024. Any changes to a school’s Spotlight rating that
                  occurred since then will be reflected in the 2026 College Free Speech Rankings.
                </p>

                <h2>
                  <em>Overall Score</em>
                </h2>
                <p>
                  To create an overall score for each college, we first summed the following student
                  subcomponents: “Comfort Expressing Ideas,” “Self-Censorship,” “Mean Tolerance,”
                  “Disruptive Conduct,” “Administrative Support,” and “Openness.” Then, we
                  subtracted the “Tolerance Difference.” By including the “Mean Tolerance” (as
                  opposed to including “Tolerance for Liberal Speakers” and “Tolerance for
                  Conservative Speakers” separately) and subtracting the “Tolerance Difference,” the
                  score accounted for the possibility that ideologically homogeneous student bodies
                  may result in a campus that appears to have a strong culture of free expression
                  but is actually hostile to the views of an ideological minority — whose views
                  students may almost never encounter on campus.
                </p>
                <p>
                  Then, to further account for the speech climate on an individual campus, we
                  incorporated behavioral components. A school earned two bonus points each time it
                  unequivocally defended free expression during a campus speech controversy — a
                  rating of “High Honors” for its public response to a speech controversy. For
                  instance, when the student government at Arizona State University opposed a
                  registered student group’s invitation to Mohammed el-Kurd to speak on campus, and
                  other members of the campus community petitioned the university to disinvite
                  el-Kurd, a university spokesperson responded:
                </p>
                <p style={{ marginLeft: '4rem' }}>
                  The university is committed to a safe environment where the free exchange of ideas
                  can take place . . . As a public university, ASU adheres to the First Amendment
                  and strives to ensure the fullest degree of intellectual freedom and free
                  expression. All individuals and groups on campus have the right to express their
                  opinions, whatever those opinions may be, as long as they do not violate the
                  student code of conduct, student organization policies, and do not infringe on
                  another student’s individual rights.
                </p>
                <p>el-Kurd spoke successfully on campus, and we awarded ASU two bonus points.</p>
                <p>
                  A school earned one bonus point for responding to a speech controversy by making a
                  public statement that strongly defends the First Amendment but is not as
                  full-throated a defense as a “High Honors” statement. These statements received
                  the rating of “Honors.” For instance, at New York University, NYU Law Students for
                  Palestine and Jewish Law Students for a Free Palestine called for the cancellation
                  of an event featuring Robert Howse and Michal Cotler-Wunsh, because Cotler-Wunsh
                  supports the occupation of Palestine. The event was co-sponsored by a student
                  group, NYU’s Jewish Law Students Association, as well as the president's office
                  and the Bronfman Center for Jewish Life. NYU did not cancel the event, and
                  protesters interrupted Cotler-Wunsh several times during his remarks before
                  voluntarily leaving, allowing the event to resume and conclude successfully. The
                  dean of the law school said the following in response:
                </p>
                <p style={{ marginLeft: '4rem' }}>
                  The principles of free speech and inquiry are complemented by debate, challenge
                  and protest . . . While dissent may be vigorous, it must not interfere with the
                  speaker’s ability to communicate — which is exactly why, should those interrupters
                  not have left on their own accord, they would be subject to discipline.
                </p>

                <p>
                  We awarded one point for this response, which occurred in 2024, then we set this
                  bonus to decrease by one-quarter of a point for each year that passes.
                </p>
                <p>
                  We also applied penalties when a school sanctioned a scholar, student, or student
                  group, or deplatformed a speaker.
                </p>
                <p>
                  A school lost up to five points each time it sanctioned (e.g., investigated,
                  suspended, or terminated) a scholar. When the sanction did not result in
                  termination the school received a penalty of one point, which we set to decrease
                  by one-quarter of a point each year: This meant penalizing a school a full point
                  for sanctioning a scholar in 2024, three-quarters of a point for sanctioning a
                  scholar in 2023, half a point for sanctioning a scholar in 2022, and one-quarter
                  of a point for sanctioning a scholar in 2021. However, if the administration
                  terminated the scholar, we subtracted three points, and if that scholar was
                  tenured, we subtracted five points. We applied full penalties for termination for
                  four years, then set them to decline by one-quarter of a point each year. So, a
                  penalty for termination that occurred in 2020 has just now started to decay.
                </p>
                <p>
                  A school lost up to three points for sanctioning students or student groups. When
                  the sanction did not result in expulsion, the revocation of acceptance, the denial
                  or revoking of recognition, suspension, or termination of a student’s campus
                  employment (e.g, as a resident assistant) the school received a penalty of one
                  point. Like with scholar sanctions that did not result in termination, we set
                  these penalties to decrease by one-quarter of a point each year. If a school
                  suspended a student or terminated their campus employment, we penalized it two
                  points. We also set these penalties to decrease by one-quarter of a point each
                  year. However, if a school denied or revoked a student group’s recognition,
                  expelled a student, or revoked their acceptance, it was penalized three points. We
                  applied these penalties in full for four years, and then set them to decline by
                  one-quarter of a point each year.
                </p>
                <p>
                  Regarding deplatforming attempts, a school was penalized one point if an invited
                  speaker withdrew because of the controversy caused by their upcoming appearance on
                  campus or if an event was postponed in response to a controversy. We set this
                  penalty to decrease by a quarter of a point each year. Schools where an attempted
                  disruption occurred received a penalty of two points. We applied this penalty for
                  four years, then set it to decrease by one-quarter of a point each year. Schools
                  with deplatforming attempts that resulted in event cancellations, preemptive
                  rejections of speakers, removal of artwork on display, the revocation of a
                  speaker’s invitation, or a substantial event disruption were penalized three
                  points. We applied these penalties in full for four years, then set them to
                  decline by one-quarter of a point each year.
                </p>
                <p>
                  After we applied bonuses and penalties, we standardized each school’s score by
                  group — “Warning” schools and other schools — making the average score in each
                  group 50.00 and the standard deviation 10.00. Following standardization, we added
                  one standard deviation to the final score of colleges who received a “green light”
                  rating for their speech codes. We also subtracted half a standard deviation from
                  the final score of colleges that received a “yellow light” rating, one standard
                  deviation from the final score of schools that received a “red light” rating, and
                  two standard deviations from schools that received a “Warning” rating.
                </p>
                <p style={{ width: '100%', textAlign: 'center' }}>
                  <em>
                    Overall Score = (50 + (Z
                    <small style={{ color: 'black', opacity: 1 }}>Raw Overall Score</small>)(10)) +
                    FIRE Rating
                  </em>
                </p>
              </div>

              <div className="mb-6 pt-5" ref={teamElement} id="team">
                <h1>The Team</h1>

                <h4>About FIRE</h4>
                <p>
                  The Foundation for Individual Rights and Expression (FIRE) is a nonpartisan,
                  nonprofit organization dedicated to defending and sustaining the individual rights
                  of all Americans to free speech and free thought. These rights include freedom of
                  speech, freedom of association, due process, legal equality, religious liberty,
                  and sanctity of conscience — the most essential qualities of liberty. FIRE also
                  recognizes that colleges and universities play a vital role in preserving free
                  thought within a free society. To this end, we place a special emphasis on
                  defending these rights of students and faculty members on our nation’s campuses.
                </p>

                <h4>About College Pulse</h4>
                <p>
                  College Pulse is a survey research and analytics company dedicated to
                  understanding the attitudes, preferences and behaviors of today's college
                  students. College Pulse delivers custom data-driven marketing and research
                  solutions, utilizing its unique American College Student Panel™ that includes
                  over 850,000 college students and recent alumni from more than 1,500 two- and
                  four-year colleges and universities in all 50 states. For more information, visit{' '}
                  <a
                    href="https://collegepulse.com"
                    target="_blank"
                    referrer="noreferrer"
                    className="has-text-underline has-text-success"
                    rel="noreferrer"
                  >
                    https://collegepulse.com/
                  </a>{' '}
                  or College Pulse's social Twitter account{' '}
                  <a
                    href="https://twitter.com/CollegeInsights"
                    target="_blank"
                    referrer="noreferrer"
                    className="has-text-underline has-text-success"
                    rel="noreferrer"
                  >
                    @CollegeInsights.
                  </a>
                </p>
              </div>
              <div className="mb-6 pt-5" ref={schoolsElement} id="schools-section">
                <h1 className="mb-0 pb-0">The Schools</h1>
                <p className="mt-2 is-size-6">
                  The following {schoolTotalCountData.table[1].length} colleges were chosen to
                  represent a variety of colleges in the United States, including large public
                  universities, small private colleges, religiously-affiliated colleges such as
                  Brigham Young University, and Ivy League colleges. Unless otherwise noted, the
                  undergraduates surveyed are from the flagship campus.
                </p>

                <table class="c53 table is-bordered">
                  <thead>
                    <tr class="c13">
                      <td class="c12" colspan="1" rowspan="1">
                        <p class="c1">
                          <span class="c2">Institution</span>
                        </p>
                      </td>
                      <td class="c10" colspan="1" rowspan="1">
                        <p class="c3">
                          <span class="c2">Number of Undergraduates Sampled</span>
                        </p>
                        <p class="c3 c36">
                          <span class="c2"></span>
                        </p>
                      </td>
                    </tr>
                  </thead>
                  <tbody>
                    {schoolTotalCountData &&
                      schoolTotalCountData.table[1]
                        .sort((a, b) => b.count - a.count)
                        .map(school => (
                          <tr class="c4">
                            <td class="c12" colspan="1" rowspan="1">
                              <p class="c1">
                                <span class="c2">{school.value}</span>
                              </p>
                            </td>
                            <td class="c10" colspan="1" rowspan="1">
                              <p class="c3">
                                <span class="c2">{school.count}</span>
                              </p>
                            </td>
                          </tr>
                        ))}
                  </tbody>
                </table>
              </div>
              <div className="mb-6 pb-6 pt-5" ref={contactElement} id="contact-section">
                <h1>
                  Have more questions about the rankings? <br />
                  Contact us.
                </h1>
                <div className="mb-6 pb-6" id="contact" />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default Methodology
